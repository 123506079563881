@media only screen and (min-width: 768px) {
  /* tablets and desktop */
  #player {
    min-height: 500px;
    aspect-ratio: 13/10;
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .left-content {
    text-align: center;
  }
}

#player {
  aspect-ratio: 13/10;
  /* height: 100%; */
  /* width: 100%; */
  cursor: grab;
  max-width: 100vw;
  max-height: 100vh;
  margin: auto;
}

.player-container {
  aspect-ratio: 13/10;
  /* height: 500px; */
}
