/*
::placeholder {
  color: #c5a362;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #c5a362;
}

::-ms-input-placeholder {
  color: #c5a362;
}
*/
.player-buttons {
  margin-left: 10px;
  margin-right: 10px;
}
