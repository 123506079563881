#header-logo{
    /* max-width: 15vw; */
    margin: 10px;
    /* filter: drop-shadow(0 0 0.1rem black); */
    align-content: center;
}

.header{
    width: 100vw;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    height: 100px;
}