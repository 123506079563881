@media only screen and (min-width: 768px) {
  /* tablets and desktop */
  .swiper-image {
    /* max-height: 100vh; */
    aspect-ratio: 13/10;
  
    max-width: 100vw;
    max-height: 500px;
    
    /* height: 500px; */
    /* height: 50%; */
    cursor: -webkit-grab;
    cursor: grab;
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .swiper-image {
    /* max-height: 100vh; */
    aspect-ratio: 13/10;
  
    max-width: 100vw;
    max-height: 100vh;
    
    /* height: 500px; */
    /* height: 50%; */
    cursor: -webkit-grab;
    cursor: grab;
  }
}

.landing {
  /* background-color: #2C2C2C; */
  /* color: white; */
  max-height: 100vh;
}

.go-back-btn{
  border: 1px solid #c5a362;
  border-radius: 50%;
  background-color: #c5a362;
  color: #ffffff;
  height: 30px;
  width: 30px;
  margin: 5px;
}

.swiper-image {
  /* max-height: 100vh; */
  aspect-ratio: 13/10;

  /* max-width: 100vw; */
  /* max-height: 100vh; */
  
  /* height: 500px; */
  /* height: 50%; */
  cursor: -webkit-grab;
  cursor: grab;
}
.swiper-container {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  /* // CSS Grid/Flexbox bug size workaround
    // @see https://github.com/kenwheeler/slick/issues/982 */
  min-height: 0;
  min-width: 0;
}

:root {
  --swiper-theme-color: #c5a362 !important;
}

.flag{
  margin: 5px;
}