/* Global */
.logged-in {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.logged-in :first-child {
  justify-self: start;
}

/* Left Side  */
.left-content {
  margin: 10px;
  width: 400px;
  text-align: left;
}
.logged-in {
  align-items: center;
  justify-content: center;
}

/* Right Side */
.right-content {
  margin: 0 auto;
  margin-top: 10%
}

.hero-image {
  width: 100%;
  max-height: 500px;

  justify-content: center;
  margin-top: 30%
}
.logout-button {
  margin: 10px;
  float: right;
}

.mh-title {
  color: #c5a362;
}
.circle-title {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  color: var(--title-color);
  --title-color: #c5a362;
}

.badge {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  background: var(--title-color);
  color: white;
}

.right-btn {
  display: inline-block;
  margin: 10px;
  font-weight: 500;
}

@media only screen and (min-width: 768px) {
  /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
  /* phones */
  .left-content {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  /* portrait phones */
}

.canvas___BrMgq-custom{
  pointer-events: none;
}

.log-out-btn{
  /* border-radius: 50%; */
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #c5a362;
  border-radius: 3px;
  padding: 5px;
}